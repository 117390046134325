<template>
  <div class="header-wrapper">
    <div class="container p-2 d-flex justify-content-between align-items-center">
      <a href="https://ideabuddy.com" target="_blank" class="logo">
        <img src="@/assets/img/logos/ideaBuddy-color.svg" alt="">
      </a>
      <ib-button
        v-if="isLoggedIn"
        outline
        variant="black"
        size="sm"
        @click="$router.push({name: 'home'})"
      >
        Back to IdeaBuddy
      </ib-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppSumoHeader',

  computed: {
    ...mapGetters('user', ['isLoggedIn'])
  }
}
</script>

<style scoped>
.header-wrapper {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.header-wrapper .container {
  max-width: 1200px;
}
</style>
