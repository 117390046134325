<template>
  <div
    class="app-sumo-container"
    :style="{background: 'url(' + require('../../assets/img/illustrations/mountain-saturized-op5.png') + '), #ffffff', }"
  >
    <dealify-header class="mb-4" />
    <dealify-form class="m-0 m-auto" />
  </div>
</template>

<script>
import DealifyForm from '@/views/Dealify/Components/DealifyForm'
import DealifyHeader from '@/views/Dealify/Components/DealifyHeader'

export default {
  name: 'Signup',

  components: {
    DealifyForm,
    DealifyHeader
  }
}
</script>

<style scoped>
.app-sumo-container {
  height: 100vh;
  background-size: cover !important;
  background-position: center bottom -200px !important;
  background-repeat: no-repeat !important;
}
</style>
