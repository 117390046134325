import { render, staticRenderFns } from "./Signup.vue?vue&type=template&id=94bee316&scoped=true"
import script from "./Signup.vue?vue&type=script&lang=js"
export * from "./Signup.vue?vue&type=script&lang=js"
import style0 from "./Signup.vue?vue&type=style&index=0&id=94bee316&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94bee316",
  null
  
)

export default component.exports